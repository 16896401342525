import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { fetchTrackingPageData, fetchClaimPageData } from "./api";
import { getErrorMessage } from "./helpers";

/**
 * @template T
 * @typedef {ReturnType<typeof import("react").useState<T>>} useState
 */

// Get the order referrence and tracking number from the URL.
// This is how we pass order credentials to tracking app.
// @tracking_number => the id after host name. Can be tracking number or order reference
// @tracking_number_param => the param to tracking_number. Can be empty string if tracking number is actual tracking number.
// If tracking number is order reference we pass tracking_number_param that is actual tracking number.
// =============
// This solution allows us speed up request to db and keep tracking more privacy without host restrictions.
// =============
// Upd 2024: It was taken out from App.js during refactoring.
export function useTrackingPageParams() {
  const { trackingNumber } = useParams();
  const { search } = useLocation();

  const trackingNumberParam = useMemo(() => new URLSearchParams(search).get('tracking_number'), [search]);

  return useMemo(() => ({ trackingNumber, trackingNumberParam }), [trackingNumber, trackingNumberParam]);
}

export function useClaimPageParams() {
  const { id } = useParams();
  const { search } = useLocation();
  
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return useMemo(() => ({
    id,
    reference:  searchParams.get('reference'),
    trackingNumber:  searchParams.get('tracking_number'),
  }), [id, searchParams]);
}

export function useClaimPageData(id) {
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const { data } = await fetchClaimPageData(id);
        setData(data);
        setError(null);
      } catch (err) {
        setData(null);
        setError(getErrorMessage(err));
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  return useMemo(() => ({ data, setData, error, loading }), [data, setData, error, loading]);
}

export function useTrackingPageData(trackingNumber, trackingNumberParam) {
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const { data } = await fetchTrackingPageData(trackingNumber, trackingNumberParam);
        setData(data);
        setError(null);
      } catch (err) {
        setData(null);
        setError(err.response.status === 404 ? 'Sorry, your tracking number is incorrect' : err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [trackingNumber, trackingNumberParam]);

  return useMemo(() => ({ data, error, loading }), [data, error, loading]);
}