// @ts-check
import { isArray, isPlainObject } from 'lodash';

const DEFAULT_CLAIMS_ERROR_MESSAGE = 'Something went wrong while creating your claim request. Please try again later or contact our support team if the problem persists.';

/** 
 * @param {import('axios').AxiosError} err 
 * @param {string} defaultMessage - error message that will be shown if response error details are not available.
 * @returns {string} human-readable error message.
 */
export function getErrorMessage(err, defaultMessage = DEFAULT_CLAIMS_ERROR_MESSAGE) {
  if (!err.response) {
    return err.message;
  }

  if (!err.response.status || err.response.status === 500) {
    return defaultMessage;
  }

  return getErrorMessageFromErrorField(err.response.data);
}

/** 
 * Returns the error message from the error field, which can potentially be a nested structure.
 * 
 * @param {Array | object | string} field
 */
function getErrorMessageFromErrorField(field) {
  if (isPlainObject(field)) {
    return getErrorMessageFromErrorField(Object.values(field)[0]);
  } else if (isArray(field)) {
    return getErrorMessageFromErrorField(field[0]);
  } else {
    return field;
  }
}
