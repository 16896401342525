// @ts-check
import { getErrorMessage } from './helpers';

export const CLAIM_EDITABLE_STATES = ['draft', 'awaiting_approval'];

/**
 * Check whether the form should be disabled based on its state.
 * 
 * @param {import('./api').Claim} claim 
 * 
 * @returns {boolean}
 */
export const checkIfFormShouldBeDisabled = (claim) => 
  !CLAIM_EDITABLE_STATES.includes(claim?.state);

/** 
 * Map uploaded file to successfully uploaded file.
 * 
 * @typedef SelectedFile
 * @type {object}
 * @property {File} data
 * @property {SelectedFileStatus} status
 * @property {string} [error]
 * 
 * @typedef {'loading' | 'success' | 'failure'} SelectedFileStatus
 * 
 * @param {SelectedFile} file
 * @param {import('./api').UploadedFile} uploadedFile
 * 
 * @returns {SelectedFile}
 */
export const mapUploadedFileToSelectedFile = ({ data }, uploadedFile) =>
  uploadedFile.data.status === "fulfilled" 
    ? { data, status: "success" }
    : { data, status: "failure", error: getErrorMessage(uploadedFile.data.reason, 'Failed to upload this file. Click to reupload.') }
