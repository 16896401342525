import { Input, Label, FormGroup, FormFeedback } from 'reactstrap';
import classNames from  'classnames';
import PropTypes from 'prop-types';

/** Wraps list of checkboxes with reactstrap's <FormGroup> to make it compatible with react-hook-form. */
export function FormCheckboxList({ 
  name, 
  items,
  disabled,
  register,
  registerOptions,
  getFieldState,
}) {
  const { ref, ...registerProps } = register(name, registerOptions);

  const { error, invalid } = getFieldState(name);

  return (
    <>
      <div className={classNames('form-control', { 'disabled': disabled, 'is-invalid': invalid })}>
        {items.map((item) => {
          const inputId = `${name}-${item.id}`;

          return (
            <FormGroup check key={item.id}>
              <Input 
                type="checkbox" 
                value={item.id} 
                id={inputId}
                innerRef={ref}
                {...registerProps}
              />
              <Label check for={inputId}>{item.title}</Label>
            </FormGroup>
          );
        })}
      </div>

      <FormFeedback>{error?.message}</FormFeedback>
    </>
  )
};

FormCheckboxList.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  register: PropTypes.func.isRequired,
  registerOptions: PropTypes.object,
  getFieldState: PropTypes.func.isRequired,
}