import { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { FormGroup, FormText, FormFeedback, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import byteSize from 'byte-size';

import { uploadFiles } from '../utils/api';
import { mapUploadedFileToSelectedFile } from '../utils/claims.utils';

import { IconButton } from './IconButton';

/** Wraps reactstrap's <FormGroup> to make it compatible with react-hook-form. */
export function FormGroupFile({ 
  name,
  control,
  controlRules,
  disabled,
  defaultValue,
  labelContent, 
  formTextContent,
  fileType = 'file',
  accept = '*'
}) {

  const { 
    field, 
    fieldState: { invalid, error, isTouched },
  } = useController({ name, disabled, defaultValue, control, rules: controlRules });

  /** List of files selected in this form group. */
  const [selectedFiles, setSelectedFiles] = useState([]);
  /** List of files that have been successfully uploaded to the server. This will be emitted as value of given control. */
  const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      const fileNames = defaultValue.map(({ file }) => file.split('/').at(-1));

      setSelectedFiles(
        defaultValue.map(({ file }, idx) => ({ 
          status: disabled ? 'readonly' : 'success', 
          data: { value: file, name: fileNames[idx] } 
        }))      
      );
  
      setSuccessfullyUploadedFiles(
        defaultValue.map(({ file }, idx) => ({ 
          name: fileNames[idx],
          data: { status: "fulfilled", value: file } 
        }))
      );
    }
  }, [disabled, defaultValue]);

  // Notify form when uploaded files were changed.
  useEffect(() => {
    // Map files to the format expected by the server.
    const mappedFiles = successfullyUploadedFiles.map(
      ({ data }) => ({ file: data.value, file_type: fileType })
    );

    if (isTouched || !isEqual(defaultValue, mappedFiles)) {
      field.onChange(mappedFiles);
    }
  }, [isTouched, field.onChange, successfullyUploadedFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  const addSelectedFiles = async (files) => {
    // Filter out already selected files.
    const filesToUpload = Array.from(files).filter((file) => 
      !selectedFiles.some((item) => item.data.name === file.name)
    );

    // Do nothing if filesToUpload is empty.
    if (!filesToUpload) return;

    // Add selected files to selectedFiles list in loading state.
    setSelectedFiles((prev) => [
      ...prev,
      ...filesToUpload.map((file) => ({ data: file, status: 'loading' }))
    ]);

    const uploadedFiles = await uploadFiles(...filesToUpload);

    // Update state of newly selected files.
    setSelectedFiles((prev) => prev.map((file) => {
      const uploadedFile = uploadedFiles.find((item) => item.name === file.data.name);

      if (!uploadedFile) {
        return file;
      }

      return mapUploadedFileToSelectedFile(file, uploadedFile);
    }));

    // Add successfully uploaded files to the successfullyUploadedFiles.
    setSuccessfullyUploadedFiles((prev) => [
      ...prev, 
      ...uploadedFiles.filter((file) => file.data.status === "fulfilled")
    ]);
  }

  const removeSelectedFile = (file) => {
    const fileName = file.data.name;
    setSelectedFiles((prev) => prev.filter((item) => item.data.name !== fileName));
    setSuccessfullyUploadedFiles((prev) => prev.filter((item) => item.name !== fileName));
  }

  const reuploadSelectedFile = async ({ data: file }) => {
    // Set status of given file to "loading".
    setSelectedFiles((prev) => 
      prev.map((item) => item.data.name === file.name 
        ? { data: file, status: 'loading' } 
        : item
      )
    );

    const [uploadedFile] = await uploadFiles(file);

    // Update state of reuploaded file.
    setSelectedFiles((prev) => prev.map((item) => {
      if (item.data.name !== uploadedFile.name) {
        return item;
      }

      return mapUploadedFileToSelectedFile(item, uploadedFile);
    }));

    // Add uploaded file to the successfullyUploadedFiles if it was successfully uploaded.
    if (uploadedFile.data.status === "fulfilled") {
      setSuccessfullyUploadedFiles((prev) => [...prev, uploadedFile]);
    }
  }

  const renderFileName = (file) => {
    const uploadedFile = successfullyUploadedFiles.find((item) => item.name === file.data.name);

    const fileNameEl = (
      uploadedFile
        ? <a className="selected-files__name" href={uploadedFile.data.value}>{file.data.name}</a>
        : file.error 
          ? <span className="selected-files__name selected-files__name--error" title={file.error}>{file.data.name}</span>
          : <span className="selected-files__name">{file.data.name}</span>
    );

    const fileSize = file.data.size ? byteSize(file.data.size).toString() : null;

    return <>{fileNameEl}&nbsp;{fileSize && <span>({fileSize})</span>}</>;
  }

  const renderDeleteButton = (file) => (
    <IconButton 
      iconName="icon-delete"
      title="Click to remove this file from the selection."
      disabled={disabled}
      onClick={() => removeSelectedFile(file)} 
    />
  )

  const renderReuploadButton = (file) => (
    <IconButton 
      iconName="icon-error"
      title="Failed to upload this file. Click to reupload."
      disabled={disabled}
      onClick={() => reuploadSelectedFile(file)} 
    />
  )

  const renderFileActions = (file) => {
    switch (file.status) {
      case "loading": 
        return (
          <div className="spinner-wrap">
            <Spinner size="sm">Loading...</Spinner>
          </div>
        )
      case "success":
        return renderDeleteButton(file);
      case "failure":
        return (
          <>
            {renderReuploadButton(file)}
            {renderDeleteButton(file)}
          </>
        );
      default: 
        return null;
    }
  }

  return (
    <FormGroup className="mb-3">
      {!!labelContent && (
        <Label for={name}>{labelContent()}</Label>
      )}

      <Input 
        type="file"
        multiple
        id={name}
        name={name}
        accept={accept}
        // Input value should always show as empty, since we manually show the selected files below.
        value={''}
        invalid={invalid}
        disabled={disabled}
        innerRef={field.ref}
        onBlur={field.onBlur}
        onChange={(evt) => addSelectedFiles(evt.target.files)}
      />

      <FormFeedback>{error?.message}</FormFeedback>

      {!!formTextContent && (
        <FormText className="d-block">{formTextContent()}</FormText>
      )}
      

      {selectedFiles?.length > 0 && (
        <FormText className="d-block selected-files">
          Selected files: {
            <ul>
              {selectedFiles.map((file) => 
                <li key={file.data.name} className="selected-files__item">
                  {renderFileName(file)}
                  {renderFileActions(file)}
                </li>
              )}
            </ul>
          }
        </FormText>
      )}
    </FormGroup>
  )
}

FormGroupFile.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  controlRules: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.array,
  labelContent: PropTypes.func,
  formTextContent: PropTypes.func,
  fileType: PropTypes.oneOf(['sales_invoice', 'correspondence', 'item_image', 'file']),
}
