import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet'

import { useClaimPageParams, useClaimPageData, useTrackingPageData } from '../utils/hooks';
import { checkIfFormShouldBeDisabled } from '../utils/claims.utils';
import { ClientThemeContext } from '../constants/themes';

import Loader from "../components/Loader";
import { Empty, EmptyWrapper } from '../components/Empty';
import { ClaimForm } from '../components/ClaimForm';


export function ClaimPage() {
  const containerRef = useRef(null);

  const [isFormDisabled, setIsFormDisabled] = useState();

  const { theme, updateTheme } = useContext(ClientThemeContext);
  
  const { id, reference, trackingNumber } = useClaimPageParams();

  const { data: claimData, setData: setClaimData, loading: isClaimLoading } = useClaimPageData(id);

  // TODO: load this data in root component (blocked by Home.js)
  const { data: trackingPageData, loading: isTrackingPageLoading } = useTrackingPageData(reference, trackingNumber);

  useEffect(() => {
    if (trackingPageData?.preferences) {
      updateTheme(trackingPageData.preferences);
    }
  }, [trackingPageData, updateTheme]);

  useEffect(() => setIsFormDisabled(checkIfFormShouldBeDisabled(claimData)), [claimData])

  const pageTitle = useMemo(() => `Claim Request | ${theme.config.company_name || theme.config.name}`, [theme]);

  const order = useMemo(() => trackingPageData?.orders?.find((item) => item.id === claimData?.order), [trackingPageData, claimData]);

  const fulfillment = useMemo(() => order?.fulfillments?.[0], [order]);

  const isLoading = useMemo(() => isClaimLoading || isTrackingPageLoading, [isClaimLoading, isTrackingPageLoading])

  if (isLoading) {
    return <Loader />;
  }

  if (!order && !isLoading) {
    return <Empty trackingNumber={reference} trackingNumberParam={trackingNumber} />
  }

  if (!claimData && !isLoading) {
    return (
      <EmptyWrapper>
        <div>
          <h2>Whoops, it looks like there is no claim with the specified parameters.</h2>
          <p>Please make sure the URL is correct.</p>
        </div>
      </EmptyWrapper>
    );
  }

  const afterFormSubmit = (updatedClaim) => {
    if (updatedClaim) {
      setClaimData(updatedClaim);
      setIsFormDisabled(checkIfFormShouldBeDisabled(updatedClaim));
    }

    containerRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
      </Helmet>
  
      <div className="main-content">
        <div className="claim-container" ref={containerRef}>
          <div className="claim-title">
            Claim Request
          </div>

          <div className="claim-card">
            <ClaimForm 
              order={order} 
              fulfillment={fulfillment}
              claim={claimData}
              disabled={isFormDisabled} 
              afterFormSubmit={afterFormSubmit} 
            />
          </div>
        </div>
      </div>
    </>
  );
}