// @ts-check
import { createContext } from 'react';

/** 
 * @typedef ToastPayload
 * @type {object}
 * @property {?import("reactstrap").ToastHeaderProps} headerProps
 * @property {!import("reactstrap").ToastBodyProps} bodyProps
 */

export const ToastsContext = createContext({
  /** @param {ToastPayload} props - {@link ToastPayload} */ 
  showToast: (props) => {},
 });
