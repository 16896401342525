import { useMemo } from 'react';
import { Col, Form, FormGroup, Label, Alert } from 'reactstrap';
import { useForm, FormProvider } from "react-hook-form"
import { capitalize } from 'lodash';

import { updateClaim } from '../utils/api';
import { getErrorMessage } from '../utils/helpers';
import { CLAIM_REASONS_LIST } from '../constants/claims';

import { FormInput } from './FormInput';
import { FormGroupFile } from './FormGroupFile';
import { FormCheckboxList } from './FormCheckboxList';
import { ButtonWithSpinner } from './ButtonWithSpinner';

const FILE_KEYS = ['sales_invoice_files', 'correspondence_files', 'images_files', 'other_files'];

const FILE_ACCEPTS = 'image/jpeg, image/png, application/pdf, application/zip, application/x-rar-compressed, application/x-7z-compressed, image/gif';

/** List of claim states to show message on why the form is disabled. Under Review/Processing/Closed/Rejected */
const DISABLED_MESSAGE_STATES = ['under_review', 'processing', 'closed', 'rejected'];

export function ClaimForm({ 
  order, 
  fulfillment, 
  claim, 
  disabled, 
  afterFormSubmit 
}) {

  const CURRENT_DATE = useMemo(() => new Date().toISOString().split('T')[0], []);

  const defaultFilesValues = useMemo(() => {
    const filesData = {
      sales_invoice_files: [],
      correspondence_files: [],
      images_files: [],
      other_files: [],
    };

    if (!claim) {
      return filesData;
    }

    for (const file of claim.files) {
      switch (file.file_type) {
        case 'sales_invoice':
          filesData.sales_invoice_files.push(file);
          break;
        case 'correspondence':
          filesData.correspondence_files.push(file);
          break;
        case 'item_image':
          filesData.images_files.push(file);
          break;
        default:
          filesData.other_files.push(file);
          break;
      }
    }

    return filesData;
  }, [claim])

  const defaultValues = useMemo(() => {
    return {
      claim_reason: claim?.claim_reason ?? CLAIM_REASONS_LIST[0].value,
      affected_items: claim?.affected_items.map((item) => String(item.order_item)) ?? [],
      description: claim?.description ?? '',
      date_of_issue: claim?.date_of_issue ?? CURRENT_DATE,
      value_of_order: Number(claim?.value_of_order ?? 0),
      ...defaultFilesValues,
    };
  }, [CURRENT_DATE, claim, defaultFilesValues]);

  const formMethods = useForm({ disabled, defaultValues, mode: 'all' });

  const { 
    watch, 
    control,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    getFieldState,
    setError,
    handleSubmit,
  } = formMethods;

  const onFormSubmit = async (data) => {
    try {
      // Reset "image_files" and "affected_items" when "lost" claim is selected.
      // We could do this on select event, but that would result in bad UX if claim was changed accidentally.
      const fileKeysSource = data.claim_reason === 'lost'
        ? FILE_KEYS.filter((item) => item !== 'images_files')
        : FILE_KEYS;

      const files = Object.entries(data)
        .filter(([key, _]) => fileKeysSource.includes(key))
        .flatMap(([_, files]) => files);

      const affectedItems = data.claim_reason === 'lost' 
        ? undefined
        : data.affected_items.map((item) => ({ order_item: Number(item) }));

      const payload = { 
        order: order.id,
        customer_name: order.destination_address.full_name,
        claim_reason: data.claim_reason,
        description: data.description,
        date_of_issue: data.date_of_issue,
        value_of_order: Number(data.value_of_order),
        affected_items: affectedItems,
        files: files,
      }

      const { data: updatedClaim } = await updateClaim(payload, claim.id);

      afterFormSubmit?.(updatedClaim);
    } catch (err) {
      console.error(err);

      // Manually set form error to tell react-hook-form that submit was failed.
      setError("root.server", { type: "server", message: getErrorMessage(err) });

      afterFormSubmit?.();
    }
  }

  const renderDisabledMessage = () => {
    if (isSubmitSuccessful) {
      return (
        <Alert color="primary">
          Claim has been successfully submitted. You will be notified when it is reviewed.
        </Alert>
      );
    }

    if (DISABLED_MESSAGE_STATES.includes(claim.state)) {
      const statusDislay = claim.state.split("_").map(capitalize).join(" ");

      return (
        <Alert color="info">
          This claim is currently {statusDislay} and cannot be edited.
        </Alert>
      );
    }

    return null;
  }

  const selectedClaimReason = watch('claim_reason');

  return (
    <FormProvider {...formMethods}>
      {disabled && renderDisabledMessage()}

      {errors?.root?.server && (
        <Alert color="danger">{errors?.root?.server.message}</Alert>
      )}

      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <FormGroup row className="mb-4">
          <Label md={4}>Order reference</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{order.customer_reference || order.reference}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4}>Courier</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{fulfillment.courier_service.service_name}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="customer_name">Customer name</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{order.destination_address.full_name}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="claim_reason">
            Claim <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              name="claim_reason"
              type="select"
              className="form-control form-select"
              control={control}
              controlRules={{ required: 'Please select the reason for claim.' }}
            >
              {CLAIM_REASONS_LIST.map((item) => (
                <option key={item.value} value={item.value}>{item.text}</option>
              ))}
            </FormInput>
          </Col>
        </FormGroup>

        {/* User must select affected items unless the claim is "lost". */}
        {selectedClaimReason !== 'lost' && (
          <FormGroup row className="mb-4">
            <Label md={4} for="affected_items">
              Select Item(s) for claim <span className="text-danger">*</span>
            </Label>

            <Col md={8}>
              <FormCheckboxList 
                name="affected_items"
                items={order.order_items}
                disabled={disabled}
                getFieldState={getFieldState}
                register={register}
                registerOptions={{ required: 'Please select order items for claim.' }}
              />
            </Col>
          </FormGroup>
        )}

        <FormGroup row className="mb-4">
          <Label md={4} for="description">
            Description of issue <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="description" 
              name="description" 
              type="textarea" 
              rows="3" 
              placeholder="Description of issue. For damaged claims please specify which items were damaged."
              control={control}
              controlRules={{ required: 'Please provide some details about the problem you encountered.' }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="date_of_issue">
            Date of Issue <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="date_of_issue" 
              name="date_of_issue" 
              type="date" 
              placeholder="01.01.1970"
              max={CURRENT_DATE}
              control={control}
              controlRules={{ required: 'Please enter the date the problem occurred.' }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="value_of_order">
            Value of order <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="value_of_order" 
              name="value_of_order" 
              type="number" 
              inputMode="decimal" 
              min="0" 
              step="0.01"
              placeholder="0.00"
              control={control}
              controlRules={{
                required: 'Please enter the order value.',
                min: { value: 1, message: 'Please enter a valid order value.' },
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4}>
            Supporting documents
            <br />
            <small>Accepted file types: PDF, JPEG, PNG, GIF, ZIP, RAR, 7Z</small>
            <br />
            <small>Max file size: 10MB</small>
          </Label>

          <Col md={8}>
            <FormGroupFile 
              name="sales_invoice_files"
              fileType="sales_invoice"
              accept={FILE_ACCEPTS}
              control={control}
              controlRules={{ required: 'Please attach the sales invoice.' }}
              disabled={disabled}
              defaultValue={defaultFilesValues.sales_invoice_files}
              labelContent={() => <>Sales invoice <span className="text-danger">*</span></>}
              formTextContent={() => <>Upload a copy of your sales invoice (or equivalent), showing payment details and the order number.</>
              }
            />

            <FormGroupFile 
              name="correspondence_files"
              fileType="correspondence"
              accept={FILE_ACCEPTS}
              control={control}
              controlRules={{ required: 'Please attach the correspondence.' }}
              disabled={disabled}
              defaultValue={defaultFilesValues.correspondence_files}
              labelContent={() => <>Correspondence <span className="text-danger">*</span></>}
              formTextContent={() => <>Provide a dated copy/screenshot of any written communication you have had about the missing or damaged order.</>
              }
            />

            {/* User must provide images of the problem unless the claim is "lost". */}
            {selectedClaimReason !== 'lost' && (
              <FormGroupFile 
                name="images_files"
                fileType="item_image"
                accept={FILE_ACCEPTS}
                control={control}
                controlRules={{ required: selectedClaimReason === 'damaged' ? 'Please provide a photo of the problem you encountered.' : null }}
                disabled={disabled}
                defaultValue={defaultFilesValues.images_files}
                labelContent={() => <>Item images {selectedClaimReason === 'damaged' && <span className="text-danger">*</span>}</>}
                formTextContent={() => <>If applicable, upload photos of the items or any damage.</>}
              />
            )}

            <FormGroupFile 
              name="other_files"
              fileType="file"
              accept={FILE_ACCEPTS}
              control={control}
              disabled={disabled}
              defaultValue={defaultFilesValues.other_files}
              labelContent={() => <>Other</>}
              formTextContent={() => <>Upload any additional documentation that may help the investigation (e.g., Proof of Delivery).</>}
            />
          </Col>
        </FormGroup>

        <ButtonWithSpinner
          type="submit"
          color="primary" 
          className="w-100"
          loading={isSubmitting}
          disabled={disabled || isSubmitting}
        >
          Submit
        </ButtonWithSpinner>
      </Form>
    </FormProvider>
  )
}