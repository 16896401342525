// @ts-check
import axios from 'axios';

import { API_TOKEN, API_TRACKING, API_CLAIMS_URL, API_CLAIM_FILES_UPLOAD_URL } from "../constants";

export const fetchTrackingPageData = (trackingNumber, trackingNumberParam) => 
  axios.get(`${API_TRACKING}${trackingNumber}/`, {
    params: { tracking_number: trackingNumberParam },
    headers: { "Authorization": API_TOKEN }
  });


/**
 * @typedef Claim
 * @type {object}
 * @property {number} id
 * @property {string} state
 * @property {number} order
 * @property {string} customer_name
 * @property {string} claim_reason
 * @property {string} description
 * @property {string} date_of_issue
 * @property {string} submit_date
 * @property {string} value_of_order
 * @property {ClaimAffectedItem[]} affected_items
 * @property {ClaimFile[]} files
 * 
 * 
 * @typedef ClaimAffectedItem
 * @type {object}
 * @property {number} order_item
 * 
 * @typedef ClaimFile
 * @type {object}
 * @property {number} id
 * @property {string} file
 * @property {string} file_type
 */


/** @param {number} id - Claim ID.
 * 
 *  @returns {Promise<import('axios').AxiosResponse<Claim>>}
 */
export const fetchClaimPageData = (id) => axios.get(`${API_CLAIMS_URL}${id}/`);

/**
 * @typedef CreateClaimPayload
 * @type {object}
 * @property {number} order
 */

/** 
 * Create claim for current order.
 * @param {CreateClaimPayload} payload - {@link CreateClaimPayload}
 * @returns {Promise<import('axios').AxiosResponse.<{ id: number, order: number }>>}
 */
export function createClaim(payload) {
  return axios.post(API_CLAIMS_URL, payload);
};

/**
 * @typedef UpdateClaimPayload
 * @type {object}
 * @property {number} order
 * @property {string} customer_name
 * @property {string} claim_reason
 * @property {string} description
 * @property {string} date_of_issue
 * @property {number} value_of_order
 * @property {Array.<{ order_item: number }>} affected_items
 * @property {Array.<{ file: string, file_type: string }>} files
 */

/** 
 * Create claim for current order.
 * @param {UpdateClaimPayload} payload - {@link UpdateClaimPayload}  
 * @param {number} id - Claim ID
 * @returns {Promise<import('axios').AxiosResponse<Claim>>}
 */
export function updateClaim(payload, id) {
  return axios.patch(`${API_CLAIMS_URL}${id}/`, payload);
};

/**
 * @typedef UploadedFile
 * @type {object}
 * @property {string} name
 * @property {PromiseSettledResult.<string>} data
 */

/** 
 * Upload file to dedicated S3 storage for claims.
 * @param {...File} files - Files to upload
 * @returns {Promise<Array.<UploadedFile>>} List of uploaded files
 */
export async function uploadFiles(...files) {
  const results = await Promise.allSettled(files.map(uploadClaimFile));

  return files.map((file, idx) => ({ name: file.name, data: results[idx] }));
}

/** 
 * Upload file to dedicated S3 storage for claims.
 * @param {File} file - File to upload
 * @returns {Promise<string>} URL of the uploaded file
 */
export async function uploadClaimFile(file) {
  const { data: s3Data } = await getClaimFileUploadParams(file);  
  
  await uploadFileToS3(file, s3Data);

  return `${s3Data.url}${s3Data.fields.key}`;
}

/** 
 * @typedef S3Data
 * @type {object}
 * @property {string} url - URL of S3 storage
 * @property {S3DataFields} fields - Metadata of uploaded file
 */

/**
 * @typedef S3DataFields
 * @type {object}
 * @property {string} key
 * @property {string} acl
 * @property {string} Content-Type
 * @property {string} AWSAccessKeyId
 * @property {string} policy
 * @property {string} signature
 */

/** Get upload params for given claim file.
 * @param {File} file - File to upload
 * @returns {Promise<import('axios').AxiosResponse<S3Data>>} Upload params
 */
function getClaimFileUploadParams(file) {
  return axios.get(API_CLAIM_FILES_UPLOAD_URL, {
    params: {
      dest_key: 'claim_files',
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
    },
  });
}

/** Upload file to S3 storage.
 * @param {File} file - File to upload
 * @param {S3Data} s3Data - Upload params
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
function uploadFileToS3(file, s3Data) {
  const data = new FormData();

  for (const [key, value] of Object.entries(s3Data.fields)) {
    data.append(key, value);
  }

  data.append('file', file);

  return axios.post(s3Data.url, data);
}